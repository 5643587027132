import React from "react";

const ItemLink = props => (
  <li>
    <a href={props.url} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  </li>
);


export default ItemLink;
