import React from "react";
import styled from "styled-components";

import { Container, Row } from "react-bootstrap";
import {
  FaTwitter,
  FaReddit,
  FaFacebookF,
  FaYoutube,
  FaLinkedin,
  FaGamepad,
} from "react-icons/fa";
import {MdEmail} from "react-icons/md";
import ItemLink from "../components/item-link";


const SocialButtons = styled.div`
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    float: left;
    li {
      display: inline-block;
      padding-right: 12px;
      a {
        display: block;
        background-color: #707070;
        text-align: center;
        vertical-align: middle;
        height: 60px;
        width: 60px;
        border-radius: 20%;
        font-size: 40px;
        line-height: 60px;
        color: white;
        outline: none;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
      }

      a:hover {
        background-color: #898989;
      }
    }
  }
`;

// TODO: Add discord
const SocialBtnBar = (
  <SocialButtons>
    <ul>
    <ItemLink url="mailto:info@froliclabs.com">
        <MdEmail />
      </ItemLink>
      <ItemLink url="http://twitter.com/froliclabs">
        <FaTwitter />
      </ItemLink>
      <ItemLink url="https://www.youtube.com/channel/UCbECSBjd_m0Yg_O9fkrvSuA">
        <FaYoutube />
      </ItemLink>
      <ItemLink url="https://www.facebook.com/froliclabs">
        <FaFacebookF />
      </ItemLink>
      <ItemLink url="https://www.linkedin.com/company/frolic-labs">
        <FaLinkedin />
      </ItemLink>
      <ItemLink url="https://www.indiedb.com/company/frolic-labs">
        <FaGamepad />
      </ItemLink>
      <ItemLink url="https://www.reddit.com/r/FrolicLabs/">
        <FaReddit />
      </ItemLink>
     
    </ul>
  </SocialButtons>
);

/* 
const ServiceBox = styled.div`
  margin: 15px auto 0;
  margin-top: 12px;
  margin-left: 10px;
  padding-left: 20px;
  max-width: 400px;
  text-align: left;
`;

const mediaBox = (title, email) => {
  return (
    <ServiceBox>
      <h5 className="text-head1">{title}</h5>
      <p className="text-copy">
       
        <a
          className="text-email"
          href={email}
          target="_blank"
          rel="noopener noreferrer"
        >
          {email}
        </a>
      </p>
    </ServiceBox>
  );
}; */

const ContactBlock = () => (
  <Container>
    <Row className="justify-content-md-center">
      {SocialBtnBar}
    </Row>   
  </Container>
);

export default ContactBlock;
