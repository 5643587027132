import React from "react";
import styled from "styled-components";

import { Container, Row, Col } from "react-bootstrap";

import froliclogo from "../images/FrolicLogo-1ks.png";
import bannerImg from "../images/neon-yellow-left.jpg";

const IntroWrap = styled.div`
  padding-top: 60px;
  padding-bottom: 30px;
  text-align: center;
  color: #f8f8f8;
`;


const OceanCont = styled.div`
  background: url(${bannerImg});
  background-size: cover;
  width: 100%;
  height: 100%;
`; 
/*
const FlatCont = styled.div`
  background: #b9b9cd;
  background-size: cover;
`;
 */

const FrontHeader = () => (
  <header>
    <OceanCont>
      <IntroWrap>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="4">
              <img src={froliclogo} alt="FLogo" />
            </Col>
          </Row>
        
        </Container>
      </IntroWrap>
    </OceanCont>
  </header>
);

export default FrontHeader;
