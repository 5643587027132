import React from "react";
import styled from "styled-components";

import { Container, Row, Col, Image, Jumbotron } from "react-bootstrap";

import FrontHeader from "../components/front-header";

import GlobalStyle from "../components/global";
import PostSEO from "../components/post-seo";
import ContactBlock from "../components/contact-social";

import duneImg from "../images/banner-b.png";
import screen1 from "../images/screenshots/Dive03.jpg";
import btnSteam from "../images/BtnSteam1.png";
import btnSwitch from "../images/eshop-small.png";

const imageObj = {
  src: screen1,
  width: "300",
  height: "200",
};

const MinFooter = () => (
  <footer>
    <Container>
      <Row>
        <Col sm={{ span: 4, offset: 4 }} className="center-text">
          <p className="copyright text-copy">
            {" "}
            &copy;2024 &nbsp; Frolic Labs
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

const IndexPage = () => {
  const aboutBlurbText =
    "Frolic Labs is an Independent Video Game development studio, based in Toronto, Canada.";
  const about2 =
    "Working with local talent, we aim to create innovative and engaging player experience with high production values.";
  return (
    <>
      <FrontHeader />
      <PostSEO
        title="Frolic Labs"
        description="Frolic Labs Web page"
        image={imageObj}
      />
      <SectionWrap>
        <ContactBlock />

        <Container>
          <Row className="justify-content-md-center">
            <Col lg={{ span: 9 }}>
              <Jumbotron>
                <h3>We are Frolic Labs.</h3>
                <p className="text-email ">
                  {aboutBlurbText}
                  <br />
                  {about2}
                </p>
              </Jumbotron>
            </Col>
          </Row>
          <hr />
          <Row className="justify-content-md-center">
            <Col lg={{ span: 8 }}>
              <a
                href="https://dunesea.info"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={duneImg} />
              </a>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col lg={6}>
              <p>
                Our recent title{" "}
                <i>
                  <b>Dune Sea</b>
                </i>{" "}
                is Available for Nintendo Switch, Windows and Mac.
              </p>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="2">
              <a
                href="https://www.nintendo.com/games/detail/dune-sea-switch/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={btnSwitch} alt="Switch" />
              </a>
            </Col>
            <Col md="2">
              <a
                href="https://store.steampowered.com/app/1103120/Dune_Sea/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={btnSteam} alt="Steam" />
              </a>
            </Col>
          </Row>
        </Container>
        <Separator />
      </SectionWrap>
      <GlobalStyle />

      <MinFooter />
    </>
  );
};

const SectionWrap = styled.div`
  padding: 20px 0;
  border-top: 0px solid #e70000;
  border-bottom: 1px solid #e7e7e7;
  background-color: #fff4d4;

  .center-text {
    text-align: center;
  }
`;

const Separator = styled.div`
  padding: 20px;
`;

/*
const Featured = styled.div`
  border: 2px solid rgba(190, 190, 160, 0.3);
  background-color: rgba(192, 188, 170, 0.3);
  text-align: center;
  border-radius: 8px;
  padding: 8px;
  padding-bottom 0;

  .head-font {
    font-family: "Roboto",sans-serif;
    text-align: left;
  }

  .blurb-text {
    font-weight: 700;
  }
`; */

export default IndexPage;
